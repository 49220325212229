import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { z } from 'zod'

import { useCreateWorkspaceMutation } from '@/generated'

import FormModal from '../ui/FormModal'
import Input from '../ui/Input'

const createSchema = z.object({
  name: z.string(),
})

type FormValues = z.infer<typeof createSchema>

interface Props {
  open: boolean
  onClose: () => void
}

const CreateWorkspaceModal: FC<Props> = ({ open, onClose }) => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useCreateWorkspaceMutation({ onSettled: () => queryClient.refetchQueries(['Workspaces']) })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ resolver: zodResolver(createSchema) })

  const handleCreateWorkspace = handleSubmit(async ({ name }) => {
    const toastId = toast.loading('Lägger till workspace...')
    try {
      await mutateAsync({ name })
      toast.success('Ett nytt workspace har lagts till', { id: toastId })
      onClose()
    } catch (e) {
      toast.error('Någonting gick fel när workspace skulle läggas till', { id: toastId })
    }
  })

  useEffect(() => {
    if (!open) reset()
  }, [open, reset])

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleCreateWorkspace}
      title='Lägg till workspace'
      description={<>Fyll i önskat namn och lägg till för att skapa ett nytt workspace.</>}
      confirmButtonText='Lägg till'
      cancelButtonText='Avbryt'
    >
      <Input label='Namn' {...register('name')} error={errors.name?.message} />
    </FormModal>
  )
}

export default CreateWorkspaceModal