import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { IoAlertCircleOutline, IoInformationCircleOutline } from 'react-icons/io5'

import Modal, { DialogProps } from './Modal'
import ModalActionButtons, { ModalActionButtonProps } from './ModalActionButtons'

interface Props extends DialogProps, Pick<ModalActionButtonProps, 'onConfirm' | 'confirmButtonText' | 'cancelButtonText'> {
  title: string | ReactNode
  description?: string | ReactNode
  variant: 'info' | 'attention'
}

const AlertModal: FC<PropsWithChildren<Props>> = ({
  variant = 'info',
  title,
  description,
  onClose,
  onConfirm,
  confirmButtonText,
  cancelButtonText,
  ...props
}) => {
  return (
    <Modal {...props} onClose={onClose}>
      <div className='space-x-base flex items-start'>
        <div
          className={classNames(
            'w-xl h-xl flex flex-none items-center justify-center rounded-full',
            variant === 'info' ? 'bg-primary-10' : 'bg-attention-5',
          )}
        >
          {variant === 'info' && <IoInformationCircleOutline size={26} className='text-primary-100' />}
          {variant === 'attention' && <IoAlertCircleOutline size={26} className='text-attention-100' />}
        </div>
        <div className='flex flex-col space-x-4'>
          <Dialog.Title className='text-heading-sm' as='h1'>
            {title}
          </Dialog.Title>

          <Dialog.Description className='' as='p'>
            {description}
          </Dialog.Description>
        </div>
      </div>

      <ModalActionButtons
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        onConfirm={onConfirm}
        onCancel={onClose}
        variant={variant === 'info' ? 'primary' : 'attention'}
      />
    </Modal>
  )
}

export default AlertModal
