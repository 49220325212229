import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'

import { WorkspacePartsFragment, WorkspacesQueryVariables } from '@/generated'
import { Workspaces } from '@/graphql/Workspace'
import useOpenState from '@/hooks/useOpenState'
import workspaceSlugFromPath from '@/lib/workspaceSlugFromPath'
import { useAuth } from '@/providers/AuthProvider'

import CreateWorkspaceModal from '@/components/admin/CreateWorkspaceModal'
import WorkspaceToggle from '@/components/admin/WorkspaceToggle'
import Button from '@/components/ui/Button'
import ListItem from '@/components/ui/ListItem'
import PaginatedList from '@/components/ui/PaginatedList'
import RouteAnimationWrapper from '@/components/ui/RouteAnimationWrapper'

const SuperAdminRoute = () => {
  const { me } = useAuth()
  const navigate = useNavigate()
  const workspaceSlug = workspaceSlugFromPath()
  const [createModalOpen, openCreateModal, closeCreateModal] = useOpenState()

  useEffect(() => {
    if (!me?.isSuperAdmin) navigate(`${workspaceSlug}/admin`)
  }, [me?.isSuperAdmin, navigate, workspaceSlug])

  return (
    <RouteAnimationWrapper className='space-y-base flex flex-col'>
      <div className='flex  items-center justify-between'>
        <h3 className='text-heading-sm'>Appinstanser</h3>
        <Button className='self-end' onClick={openCreateModal}>
          + Lägg till Workspace
        </Button>
      </div>
      <CreateWorkspaceModal open={createModalOpen} onClose={closeCreateModal} />
      <PaginatedList<WorkspacePartsFragment, WorkspacesQueryVariables>
        query={Workspaces}
        queryName='Workspaces'
        dataKey='workspaces'
        pageSize={10}
        classes={{ list: 'flex flex-col space-y-half' }}
        onRenderListItem={(workspace) => (
          <ListItem key={workspace.slug} title={workspace.name} subtitle={workspace.slug} disabled={!!workspace.disabled}>
            <WorkspaceToggle workspace={workspace} disabled={workspace.slug === workspaceSlug} />
          </ListItem>
        )}
        onRenderLoadingListItem={(index) => <Skeleton height={70} key={index} />}
      />
    </RouteAnimationWrapper>
  )
}

export default SuperAdminRoute
