import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

const RouteAnimationWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames('animate-fadein flex-1', className)} {...rest}>
      {children}
    </div>
  )
}

export default RouteAnimationWrapper
