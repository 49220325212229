import { useQueryClient } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { useUpdateWorkspaceMutation, useWorkspaceQuery } from '@/generated'
import workspaceSlugFromPath from '@/lib/workspaceSlugFromPath'

import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import RouteAnimationWrapper from '@/components/ui/RouteAnimationWrapper'
import TextArea from '@/components/ui/TextArea'


const AdminSettingsRoute = () => {
  const { data } = useWorkspaceQuery({slug: workspaceSlugFromPath()})
  const workspace = useMemo(()=> data?.workspace, [data])
  const [name, setName] = useState(workspace?.name)
  const [description, setDescription] = useState(workspace?.description || '')

  const queryClient = useQueryClient()
  const { mutateAsync } = useUpdateWorkspaceMutation({ onSettled: () => queryClient.refetchQueries(['Workspace']) })

  const saveWorkspaceChanges = async () => {
    const toastId = toast.loading('Uppdaterar inställningar...')
    try {
      await mutateAsync({name, description})
      toast.success('Inställningar uppdaterade!', { id: toastId })
    } catch (e) {
      toast.error('Någonting gick fel när inställningarna skulle uppdateras.', { id: toastId })
    }
  }

  return (
    <RouteAnimationWrapper className='space-y-base flex flex-col'>
      <h3 className='text-heading-sm'>Inställningar</h3>
      <Input label="Titel" value={name} onChange={e => setName(e.currentTarget.value)} />
      <TextArea label="Beskrivning" value={description} onChange={e => setDescription(e.currentTarget.value)} />
      <Button 
        disabled={workspace?.name === name && workspace?.description === description } 
        className='ml-auto' onClick={saveWorkspaceChanges}> 
        Spara ändringar 
      </Button>
    </RouteAnimationWrapper>
  )
}

export default AdminSettingsRoute
