import { useQuery } from '@tanstack/react-query'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { IoArrowBack, IoArrowForward } from 'react-icons/io5'
import Skeleton from 'react-loading-skeleton'

import no_data_img from '@/assets/no_data.svg'
import { fetchData } from '@/lib/fetchData'
import indexedArray from '@/lib/indexedArray'

import Button from './Button'

interface Props<TData, TVariables> {
  query: string
  queryName: string
  dataKey: string
  variables?: TVariables
  pageSize?: number
  emptyMessage?: string
  classes?: { container?: string; list?: string }
  onRenderListItem: (item: TData) => ReactNode
  onRenderLoadingListItem?: (index: number) => ReactNode
}

function PaginatedList<TData = any, TVariables extends { offset?: number | null; limit?: number | null } = any>({
  query,
  queryName,
  dataKey,
  pageSize: pageSize_,
  variables,
  emptyMessage,
  onRenderListItem,
  classes = {},
  onRenderLoadingListItem,
}: Props<TData, TVariables>) {
  const pageSize = useMemo(() => pageSize_ || 5, [pageSize_])
  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
  }, [variables])

  const nextPage = useCallback(() => setPage((currentPage) => currentPage + 1), [])
  const prevPage = useCallback(() => setPage((currentPage) => currentPage - 1), [])

  const paginatedVariables = useMemo(() => ({ ...variables, offset: (page - 1) * pageSize, limit: pageSize }), [variables, page, pageSize])

  const { data, isLoading, isRefetching } = useQuery([queryName, paginatedVariables], fetchData<any, any>(query, paginatedVariables), {
    keepPreviousData: true,
  })

  const loading = isLoading || isRefetching

  const items = useMemo<TData[]>(() => data?.[dataKey] ?? [], [data, dataKey])
  const totalCount = useMemo(() => data?.[dataKey + 'Count'], [data, dataKey])

  const totalPages = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize])

  const expectedPageSize = useMemo(() => (page === totalPages ? totalCount % pageSize : pageSize), [page, pageSize, totalPages, totalCount])

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {!loading && items.map((item) => onRenderListItem(item))}
        {loading && onRenderLoadingListItem && indexedArray(expectedPageSize).map((index) => onRenderLoadingListItem(index))}

        {items.length === 0 && !loading && (
          <div className='gap-md p-md flex flex-col items-center justify-center'>
            <img src={no_data_img} alt='No data' className='w-5xl opacity-60' />
            <div className='text-dark-70'>{emptyMessage || 'Inga resultat hittades.'}</div>
          </div>
        )}
      </div>

      {items.length !== 0 && (
        <div className='mt-lg flex items-center justify-between text-black'>
          <div>
            Visar resultat <b>{(page - 1) * pageSize + 1}</b>-
            <b>{totalCount != null ? Math.min(page * pageSize, totalCount) : <Skeleton inline width={16} />}</b> av totalt{' '}
            <b>{totalCount ?? <Skeleton inline width={16} />}</b> st
          </div>

          <div className='space-x-base flex flex-row'>
            <Button onClick={prevPage} disabled={page === 1} variant='secondary' iconLeft={<IoArrowBack />}>
              Föregående
            </Button>

            <Button
              disabled={page === totalPages || totalPages === 0}
              onClick={nextPage}
              variant='secondary'
              iconRight={<IoArrowForward />}
            >
              Nästa
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default PaginatedList
