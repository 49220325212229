import { gql } from 'graphql-request'

export default gql`
  fragment WorkspaceParts on Workspace {
    id
    slug
    name
    disabled
  }
`
