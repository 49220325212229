/* eslint-disable max-len */
import { FC, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<SVGSVGElement> {
  width?: string
  height?: string
}

const Logotype: FC<Props> = (props) => {
  return (
    <svg {...props} viewBox='0 0 90 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_28_37)'>
        <path
          d='M79.3529 17.6268C85.6282 21.343 89.5 28.2155 89.5 35.6596V64.3404C89.5 71.7845 85.6282 78.657 79.3529 82.3732L55.1375 96.7135C48.8639 100.429 41.1362 100.429 34.8625 96.7135L10.6471 82.3732C4.37185 78.657 0.5 71.7845 0.5 64.3404L0.5 35.6596C0.5 28.2155 4.37185 21.343 10.6471 17.6268L34.8625 3.28646C41.1361 -0.428819 48.8639 -0.428818 55.1375 3.28646L79.3529 17.6268Z'
          fill='#F4F4FE'
          stroke='#6F69C9'
        />
        <path
          d='M48 33C48 35.7614 45.7614 38 43 38C40.2386 38 38 35.7614 38 33C38 30.2386 40.2386 28 43 28C45.7614 28 48 30.2386 48 33Z'
          fill='#6F69C9'
        />
        <path
          d='M69 48.5C69 52.6421 65.6421 56 61.5 56C57.3579 56 54 52.6421 54 48.5C54 44.3579 57.3579 41 61.5 41C65.6421 41 69 44.3579 69 48.5Z'
          fill='#6F69C9'
        />
        <path
          d='M46 59.5C46 66.4036 40.4036 72 33.5 72C26.5964 72 21 66.4036 21 59.5C21 52.5964 26.5964 47 33.5 47C40.4036 47 46 52.5964 46 59.5Z'
          fill='#6F69C9'
        />
      </g>
      <defs>
        <clipPath id='clip0_28_37'>
          <rect width='90' height='100' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logotype
