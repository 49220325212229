import classNames from 'classnames'
import { forwardRef } from 'react'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'

interface TextAreaProps extends TextareaAutosizeProps {
  label?: string
  error?: string
  helpText?: string
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, error, minRows, helpText, className, ...rest }, ref) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      {label && (
        <label htmlFor={rest.name || 'name'} className='text-dark-100 mb-xs inline-block px-1 text-sm font-bold'>
          {label}
        </label>
      )}

      <TextareaAutosize
        className={classNames(
          'text-dark-100 relative min-h-[64px] rounded-md rounded border px-[20px] py-[20px]',
          'focus:outline-primary-100 focus:outline-2',
          !!error ? 'border-attention-100 focus:outline-attention-100' : 'border-dark-20',
          rest.disabled ? 'bg-dark-5 opacity-50' : 'bg-white',
        )}
        {...rest}
        ref={ref}
      />
      {!!error && <p className='text-attention-125 mt-xs text-sm font-bold'>{error}</p>}
      {!!helpText && !error && <p className='text-dark-80 mt-xs text-sm'>{helpText}</p>}
    </div>
  )
})

TextArea.displayName = 'TextArea'

export default TextArea
