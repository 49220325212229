import classNames from 'classnames'
import { InputHTMLAttributes, forwardRef } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  helpText?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ label, helpText, error, className, ...rest }, ref) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      {label && (
        <label htmlFor={rest.name || 'name'} className='text-dark-100 mb-xs inline-block px-1 text-sm font-bold'>
          {label}
        </label>
      )}

      <input
        className={classNames(
          'text-dark-100 relative h-[64px] rounded-md rounded border px-[20px]',
          'focus:outline-primary-100 focus:outline-2',
          error ? 'border-attention-100 focus:outline-attention-100' : 'border-dark-20',
          rest.disabled ? 'bg-dark-5 opacity-50' : 'bg-white',
        )}
        {...rest}
        ref={ref}
      />
      {error && <p className='text-attention-125 mt-xs text-sm font-bold'>{error}</p>}
      {helpText && !error && <p className='text-dark-80 mt-xs text-sm'>{helpText}</p>}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
