import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { FC, Fragment, useMemo } from 'react'
import { IoCheckmark, IoChevronDown } from 'react-icons/io5'

export interface SelectOption {
  value: string
  label: string
}
interface SelectProps {
  value: string
  options: SelectOption[]
  label?: string
  disabled?: boolean
  error?: string
  placeholder?: string
  background?: 'primary' | 'complement'
  className?: string
  onChange: (value: string) => void
}

const Select: FC<SelectProps> = ({ options, value, onChange, className, disabled = false }) => {
  const selectedLabel = useMemo(() => options.find((option) => option.value === value)?.label, [value, options])

  return (
    <Transition.Root show={true} as={Fragment}>
      <div className={disabled ? 'pointer-events-none opacity-20' : ''}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enterTo='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0 sm:scale-100'
          leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
        >
          <Listbox value={value} onChange={onChange}>
            <div className='relative'>
              <Listbox.Button
                className={classNames('p-sm relative flex flex-row items-center rounded border bg-white text-left shadow-sm', className)}
              >
                <div className='mr-sm flex-1 text-sm'>{selectedLabel}</div>
                <IoChevronDown className='h-sm w-sm text-gray-400' aria-hidden='true' />
              </Listbox.Button>
              <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
                <Listbox.Options className='my-sm absolute z-50 w-full rounded border bg-white'>
                  {options.map((item: SelectOption, itemIdx: number) => (
                    <Listbox.Option
                      key={itemIdx}
                      className={({ active }) =>
                        classNames(
                          'py-sm pl-md pr-md cursor-default select-none [&:first-child]:rounded-t [&:last-child]:rounded-b',
                          active ? 'bg-primary-100 text-white' : '',
                        )
                      }
                      value={item.value}
                    >
                      <div className='flex flex-row items-center text-sm'>
                        <span className={classNames('ml-sm', item.value === value ? 'font-bold' : '')}>{item.label}</span>
                        {item.value === value ? (
                          <span className='-translate-x-sm absolute flex items-center'>
                            <IoCheckmark className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </Transition.Child>
      </div>
    </Transition.Root>
  )
}

export default Select
