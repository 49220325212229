import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import workspaceSlugFromPath from '@/lib/workspaceSlugFromPath'
const workspace_slug = workspaceSlugFromPath()

export const getStartpageRoute = () => (`/${workspace_slug}`)
export const getUserFeedRoute = () => (`/${workspace_slug}/feed`)
export const getEventsRoute = () => (`/${workspace_slug}/events`)
export const getProjectsRoute = () => (`/${workspace_slug}/projects`)
export const getUserAccountNotificationsRoute = () => (`/${workspace_slug}/account/notifications`)
export const getUserAccountEditProfileRoute = () => (`/${workspace_slug}/account/edit-profile'`)
export const getUserAccountChangePasswordRoute = () => (`/${workspace_slug}/account/change-password`)
export const getUserAccountSettingsRoute = () => (`/${workspace_slug}/account/settings`)
export const getUserAccountEditRoute = () => (`/${workspace_slug}/account/edit-profile`)
export const getUserAccountSubscriptionsIndexRoute = () => (`/${workspace_slug}/account/subscriptions`)

export const getLoginRoute = () => (`/${workspace_slug}/auth/login`)
export const getSignupRoute = () => (`/${workspace_slug}/auth/signup`)
export const getFinishSignupRoute = () => (`/${workspace_slug}/auth/signup/finish`)
export const getConfirmEmailRoute = () => (`/${workspace_slug}/auth/signup/confirm-email`)
export const getForgotPasswordRoute = () => (`/${workspace_slug}/auth/forgot-password`)

export const getCreateNodeRoute = () => (`/${workspace_slug}/create-node`)
export const getCreatePersonNodeRoute = () => (`/${workspace_slug}/create-node/person`)
export const getCreateCompanyNodeRoute = () => (`/${workspace_slug}/create-node/company`)
export const getCreateEventNodeRoute = () => (`/${workspace_slug}/create-node/event`)
export const getCreateProjectNodeRoute = () => (`/${workspace_slug}/create-node/project`)
export const getCreateOrganizationNodeRoute = () => (`/${workspace_slug}/create-node/organization`)
export const getCreateClusterNodeRoute = () => (`/${workspace_slug}/create-node/cluster`)
export const getCreateCompanyVisitNodeRoute = () => (`/${workspace_slug}/create-node/company-visit`)

export const getAdminStartpageRoute = () => (`/${workspace_slug}/admin`)
export const getAdminSettingsRoute = () => (`/${workspace_slug}/admin/settings`)
export const getAdminUsersRoute = () => (`/${workspace_slug}/admin/users`)
export const getAdminWhitelistedDomains = () => (`/${workspace_slug}/admin/whitelisted-domains`)
export const getAdminLocationsRoute = () => (`/${workspace_slug}/admin/locations`)
export const getAdminFieldsRoute = () => (`/${workspace_slug}/admin/fields`)
export const getSuperAdminRoute = () => (`/${workspace_slug}/admin/workspaces`)

export const getGraphSearchRoute = (nodes: string[]) => `/${workspace_slug}/graph/${nodes?.join('+')}`
interface GraphNodeRouteParams {
  graphNodes?: string[]
  selectedNodes?: null | string[]
}

export const useGraphRoutes = () => {
  const { searchQuery: currentSearchQuery, selectedNodeIds: currentlySelectedNodeIds } = useParams()

  /** /graph/<search-query>/<selected-nodes>/<action> */
  const getGraphNodeRoute = useCallback(
    (params?: GraphNodeRouteParams) => {
      const requestedSearchQuery = params?.graphNodes?.join('+')
      const requestedSelectedNodes = params?.selectedNodes?.join('+')

      // Guess fallback values for search query/selected nodes
      const searchQueryNodes = requestedSearchQuery ?? currentSearchQuery ?? requestedSelectedNodes
      let selectedNodes = requestedSelectedNodes
      // if (params?.selectedNodes !== null) selectedNodes ??= currentlySelectedNodeIds ?? requestedSearchQuery

      return `/${workspace_slug}/graph/${[searchQueryNodes, selectedNodes].filter((r) => r).join('/')}`
    },
    [currentSearchQuery],
  )

  const getGraphSearchRoute = useCallback((nodes?: string[]) => getGraphNodeRoute({ selectedNodes: nodes }), [getGraphNodeRoute])

  const getEditGraphNodeRoute = useCallback(() => `${getGraphNodeRoute()}/edit`, [getGraphNodeRoute])

  const getCreateGraphNodeRoute = useCallback(() => `${getGraphNodeRoute({ selectedNodes: null })}/new`, [getGraphNodeRoute])

  const getCreateSubscriptionFromGraphSearchRoute = useCallback(
    () => `${getGraphNodeRoute({ selectedNodes: null })}/subscriptions/new`,
    [getGraphNodeRoute],
  )

  return {
    getGraphNodeRoute,
    getGraphSearchRoute,
    getEditGraphNodeRoute,
    getCreateGraphNodeRoute,
    getCreateSubscriptionFromGraphSearchRoute,
  }
}

export const useAdminRoutes = () => {
  const { userId: currentUserId } = useParams()

  const getAdminUsersRoute = useCallback(() => '/admin/users', [])
  const getEditUserRoute = useCallback((userId?: string) => `/admin/users/${userId || currentUserId}/edit`, [currentUserId])

  return {
    getAdminUsersRoute,
    getEditUserRoute,
  }
}

export const useUserSubscriptionRoutes = () => {
  const { subscriptionId: currentSubscriptionId } = useParams()

  const getUserSubscriptionsRoute = useCallback(() => '/account/subscriptions', [])
  const getCreateUserSubscriptionRoute = useCallback(() => '/account/subscriptions/new', [])
  const getEditUserSubscriptionRoute = useCallback(
    (subscriptionId?: string) => `/account/subscriptions/${subscriptionId || currentSubscriptionId}/edit`,
    [currentSubscriptionId],
  )

  return {
    getUserSubscriptionsRoute,
    getEditUserSubscriptionRoute,
    getCreateUserSubscriptionRoute,
  }
}
