import React, { FC } from 'react'

import Button from './Button'

export interface ModalActionButtonProps {
  variant?: 'primary' | 'attention'
  isForm?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm?: () => void
  onCancel?: () => void
}

const ModalActionButtons: FC<ModalActionButtonProps> = ({
  variant = 'primary',
  isForm = false,
  onConfirm,
  onCancel,
  confirmButtonText = 'Bekräfta',
  cancelButtonText = 'Avbryt',
}) => {
  return (
    <div className='space-x-sm mt-md flex justify-end'>
      {onCancel && (
        <Button type='button' onClick={onCancel} variant={variant === 'primary' ? 'secondary' : 'light'}>
          {cancelButtonText}
        </Button>
      )}

      {(isForm || onConfirm) && (
        <Button onClick={onConfirm} form={isForm ? 'modal-content' : undefined} type={isForm ? 'submit' : 'button'} variant={variant}>
          {confirmButtonText}
        </Button>
      )}
    </div>
  )
}

export default ModalActionButtons
