import { User, getAuth } from 'firebase/auth'
import React, { FC, PropsWithChildren, createContext, useContext } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import { MeQuery, useMeQuery, usePingQuery } from '@/generated'

import ServerErrorPage from '@/components/ServerErrorPage'

interface AuthProviderValue {
  firebaseUser: User | null
  isLoadingFirebaseUser: boolean
  me: MeQuery['me'] | null
  isLoadingMe: boolean
  error: Error | unknown | null
}

const AuthContext = createContext<AuthProviderValue>({
  firebaseUser: null,
  isLoadingFirebaseUser: true,
  me: null,
  isLoadingMe: true,
  error: null,
})

const useAuth = () => useContext(AuthContext)

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { error: serverError } = usePingQuery()

  const [firebaseUser, isLoadingFirebaseUser, errorFirebaseUser] = useAuthState(getAuth())

  const { data, isLoading: isLoadingMe, error: userError } = useMeQuery(undefined, { enabled: !!firebaseUser })
  const me = data?.me

  if (!!serverError && import.meta.env.PROD) return <ServerErrorPage />

  return (
    <AuthContext.Provider
      value={{
        firebaseUser: firebaseUser || null,
        isLoadingFirebaseUser,
        me: me || null,
        isLoadingMe,
        error: errorFirebaseUser || userError || null,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext, useAuth }
