export default function workspaceSlugFromDomain() {
  let workspaceSlug
  switch (window.location.hostname) {
    case 'network.business.eastsweden.se':
      workspaceSlug = 'region-ostergotland'
      break
    case 'vinnova.sigbit.app':
      workspaceSlug = 'vinnova'
      break
    case 'energimyndigheten.sigbit.app':
      workspaceSlug = 'energimyndigheten'
      break
    // case '127.0.0.1': workspaceSlug = 'region-ostergotland'
    //   break
    default:
      workspaceSlug = 'default'
      break
  }
  return workspaceSlug
}
