import { Dialog } from '@headlessui/react'
import React, { FC, ReactNode } from 'react'

export interface ModalHeaderProps {
  title?: string | ReactNode
  description?: string | ReactNode
}

const ModalHeader: FC<ModalHeaderProps> = ({ title, description }) => {
  return (
    <>
      {!!title && (
        <Dialog.Title as='h1' className='text-xl font-bold'>
          {title}
        </Dialog.Title>
      )}

      {!!description && (
        <Dialog.Description as='p' className='mt-sm mb-base text-base'>
          {description}
        </Dialog.Description>
      )}
    </>
  )
}

export default ModalHeader
