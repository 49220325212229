import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'rc-tooltip/assets/bootstrap.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import App from './App'
import './css/main.css'
import './firebase'
import { AuthProvider } from './providers/AuthProvider'

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <SkeletonTheme baseColor='#F5F6F7' highlightColor='rgb(250,250,250)' borderRadius={10}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </QueryParamProvider>
        </BrowserRouter>
        <Toaster />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SkeletonTheme>
  </React.StrictMode>,
)
