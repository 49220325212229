import classNames from 'classnames'
import { CSSProperties, FC, HTMLAttributes, useMemo } from 'react'
import { IoPersonOutline } from 'react-icons/io5'
import {
  RiBriefcaseLine,
  RiBubbleChartLine,
  RiCalendarEventLine,
  RiDoorClosedLine,
  RiMapPinLine,
  RiOrganizationChart,
  RiPencilRuler2Line,
  RiPriceTag3Line,
} from 'react-icons/ri'

import { NodeType } from '@/generated'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  entity: NodeType
  size?: 'default' | 'lg' | 'xl'
  style?: CSSProperties
}

const EntityIcon: FC<Props> = ({ entity, size = 'default', className, style }) => {
  const classes = classNames(
    // Base classes
    className,
    'rounded-full flex items-center justify-center',
    'focus:outline-none focus:ring-primary-100 focus:ring-2 focus:ring-offset-1',
    'text-entity-100 bg-entity-5',
    `entity-${entity}`,
    {
      // Size
      'w-2xl h-2xl text-lg': size === 'default',
      'w-3xl h-3xl text-xl': size === 'lg',
      'w-6xl h-3xl text-xxl': size === 'xl',
    },
  )

  const Icon = useMemo(() => {
    switch (entity) {
      case NodeType.Company:
        return RiBriefcaseLine
      case NodeType.CompanyVisit:
        return RiDoorClosedLine
      case NodeType.Person:
        return IoPersonOutline
      case NodeType.Project:
        return RiPencilRuler2Line
      case NodeType.Event:
        return RiCalendarEventLine
      case NodeType.Field:
        return RiPriceTag3Line
      case NodeType.Location:
        return RiMapPinLine
      case NodeType.Cluster:
        return RiBubbleChartLine
      case NodeType.Organization:
        return RiOrganizationChart
      default:
        return RiBriefcaseLine
    }
  }, [entity])

  return (
    <span className={classes} style={style}>
      <Icon />
    </span>
  )
}

export default EntityIcon
