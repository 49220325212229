import React, { FC } from 'react'
import { IconType } from 'react-icons'

import { NodeType } from '@/generated'

import EntityIcon from './EntityIcon'

interface ListItemProps {
  nodeType?: NodeType
  icon?: IconType
  title: string
  subtitle?: string
  children?: any
  disabled?: boolean
}

const ListItem: FC<ListItemProps> = ({ nodeType, icon, title, subtitle, children, disabled = false }) => {
  const Icon = icon
  return (
    <div key={'listItem-' + title} className='p-sm px-xs flex flex-row items-center rounded border'>
      <div className='flex items-start justify-start'>
        {nodeType ? (
          <EntityIcon entity={nodeType} style={{ background: 'transparent' }} />
        ) : Icon ? (
          <Icon size={20} className='mx-sm' />
        ) : (
          <div className='ml-md' />
        )}
      </div>
      <div className={disabled ? 'opacity-30' : ''}>
        <p className='font-bold'>{title}</p>
        <p className='text-xs'>{subtitle}</p>
      </div>
      <div className='mr-sm ml-auto'>{children}</div>
    </div>
  )
}

export default ListItem
