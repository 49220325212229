import { z } from 'zod'

const schema = z.object({
  VITE_BACKEND_ENDPOINT: z.string().url().endsWith('/api'),
  VITE_MEILI_HOST: z.string().min(1),
  VITE_MEILI_API_KEY: z.string().min(1),
  VITE_FIREBASE_ENV: z.enum(['staging', 'production']).default(import.meta.env.MODE === 'production' ? 'production' : 'staging'),
})

export const formatErrors = (errors: z.ZodFormattedError<z.infer<typeof schema>, string>) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && '_errors' in value) return `${name}: ${value._errors.join(', ')}\n`

      return undefined
    })
    .filter(Boolean)

const _serverEnv = schema.safeParse(import.meta.env)

if (!_serverEnv.success) {
  // eslint-disable-next-line no-console
  console.error('❌ Invalid environment variables:\n', ...formatErrors(_serverEnv.error.format()))
  document.body.innerHTML = 'Invalid environment variables. Check console for details.'
  throw new Error('Invalid environment variables')
}

const env = { ..._serverEnv.data }

export default env
