import { getAuth } from 'firebase/auth'
import { request } from 'graphql-request'

import env from '../env'
import workspaceSlugFromPath from './workspaceSlugFromPath'

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): (() => Promise<TData>) => {

  return () => {
    return new Promise<TData>(async (resolve, reject) => {
      const id_token = await getAuth().currentUser?.getIdToken()
      const workspace_slug = workspaceSlugFromPath()

      let headers: HeadersInit = { ...options, 'x-workspace-slug': workspace_slug }
      if (!!id_token) headers = { ...headers, 'x-id-token': id_token}

      try {
        const data = await request(env.VITE_BACKEND_ENDPOINT, query, variables, headers)
        resolve(data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        reject(e)
      }
    })
  }
}
