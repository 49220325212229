import React, { FC } from 'react'
import { IoRefresh } from 'react-icons/io5'

import server_down from '@/assets/server_down.svg'

import Logotype from './topbar/Logotype'
import Button from './ui/Button'

const ServerErrorPage: FC = () => {
  return (
    <div className='p-lg flex min-h-screen flex-col'>
      <Logotype width='60px' height='60px' />
      <div className='flex flex-1 items-center justify-center'>
        <div className='flex w-full max-w-lg flex-col items-center justify-center'>
          <img src={server_down} alt='Server down' className='mb-2xl w-full' />
          <h1 className='text-heading-lg text-center'>Tillfälligt avbrott</h1>
          <p className='pt-base text-center text-lg'>
            Någonting gick fel när vi försökte nå servern. Försök att ladda om sidan eller kontakta supporten om problemet kvarstår.
          </p>
          <Button
            variant='secondary'
            iconRight={<IoRefresh size={25} />}
            className='mt-lg mx-auto'
            onClick={() => window.location.reload()}
          >
            Ladda om sidan
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ServerErrorPage
