import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import React, { FC, Fragment, MutableRefObject, PropsWithChildren } from 'react'
export interface DialogProps {
  open: boolean
  className?: string
  initialFocus?: MutableRefObject<HTMLElement | null> | undefined
  onClose: () => void
}

const Modal: FC<PropsWithChildren<DialogProps>> = ({ open, initialFocus, children, onClose, className }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='p-sm relative z-50' open={open} onClose={onClose} initialFocus={initialFocus}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='bg-dark-100 fixed inset-0 z-50 opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-50 overflow-y-auto'>
          <div className='p-md flex min-h-full items-center justify-center text-left'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={classNames('p-md relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all', className)}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
