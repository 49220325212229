import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useWorkspacesQuery } from '@/generated'
import workspaceSlugFromDomain from '@/lib/workspaceSlugFromDomain'
import workspaceSlugFromPath from '@/lib/workspaceSlugFromPath'

import Logotype from '@/components/topbar/Logotype'
import RouteAnimationWrapper from '@/components/ui/RouteAnimationWrapper'
import Select from '@/components/ui/Select'



function SelectWorkspaceRoute() {
  const navigate = useNavigate()

  const { data, isLoading } = useWorkspacesQuery({includeDisabled: false})
  const selectOptions = useMemo(()=> data?.workspaces.map(w => ({value: w.slug, label: w.name})) || [],[data])

  useEffect(() => {
    const slugFromDomain = workspaceSlugFromDomain()
    if(slugFromDomain !== 'default' && slugFromDomain !== workspaceSlugFromPath()) navigateToLogin(slugFromDomain)
  })

  const navigateToLogin = (slug:string) => {
    navigate(`/${slug}/auth/login`)
    window.location.reload()
  }

  return (
    <>
    {!isLoading && (
      <div className='p-sm min-h-screen'>
        <div className='p-lg flex w-full'>
          <Logotype width='43px' />
        </div>
        <RouteAnimationWrapper className='mt-xl px-6'>
          <div className='mx-auto flex w-full max-w-[442px] flex-col'>
            <h1 className='text-heading-lg text-primary-100'>Välj nätverk</h1>

            <p className='pt-base pb-xl text-base'>
              Denna applikation visualiserar sammanhang och ger en överblick av Östergötlands innovationsstödsystem för att öka 
              kvaliteten på det stöd vi ger till företag. Som användare kan du bland annat lägga in kontaktuppgifter, projekt och evenemang.
            </p>
            { selectOptions[0] && (
            <Select 
              options={selectOptions} 
              value={selectOptions[0].value} 
              onChange={navigateToLogin} 
              className='w-full' 
            />
            )}
          </div>
        </RouteAnimationWrapper>
      </div>
    )}
  </>
  )
}

export default SelectWorkspaceRoute