import { FC, Suspense, lazy, useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import workspaceSlugFromPath from './lib/workspaceSlugFromPath'
import SelectWorkspaceRoute from './routes/SelectWorkspaceRoute'
import { getStartpageRoute } from './routes'
import { AuthProvider, useAuth } from './providers/AuthProvider'
import { useWorkspaceQuery } from './generated'
import AdminSettingsRoute from './routes/admin/AdminSettingsRoute'
import SuperAdminRoute from './routes/admin/SuperAdminRoute'
const ChooseWorkspaceRoute = lazy(() => import ('./routes/SelectWorkspaceRoute'))

const AppLayout = lazy(() => import('@/layouts/AppLayout'))
const UnauthorizedLayout = lazy(() => import('@/layouts/UnauthorizedLayout'))
const AuthorizedUserLayout = lazy(() => import('@/layouts/AuthorizedUserLayout'))
const AccountSettingsLayout = lazy(() => import('@/layouts/AccountSettingsLayout'))

const LoginRoute = lazy(() => import('@/routes/auth/LoginRoute'))
const ForgotPasswordRoute = lazy(() => import('@/routes/auth/ForgotPasswordRoute'))
const SignupRoute = lazy(() => import('@/routes/auth/SignupRoute'))
const CreateAccountRoute = lazy(() => import('@/routes/auth/signup/CreateAccountRoute'))
const FinishSignupRoute = lazy(() => import('@/routes/auth/signup/FinishSignupRoute'))
const ConfirmEmailRoute = lazy(() => import('@/routes/auth/signup/ConfirmEmailRoute'))
const StartpageRoute = lazy(() => import('@/routes/StartpageRoute'))
const UserFeedRoute = lazy(() => import('@/routes/UserFeedRoute'))
const NotFoundRoute = lazy(() => import('@/routes/NotFoundRoute'))

const CreateNodeRoute = lazy(() => import('@/routes/create-node/CreateNodeRoute'))
const CreatePersonNodeRoute = lazy(() => import('@/routes/create-node/CreatePersonNodeRoute'))
const CreateCompanyNodeRoute = lazy(() => import('@/routes/create-node/CreateCompanyNodeRoute'))
const CreateEventNodeRoute = lazy(() => import('@/routes/create-node/CreateEventNodeRoute'))
const CreateProjectNodeRoute = lazy(() => import('@/routes/create-node/CreateProjectNodeRoute'))
const CreateOrganizationNodeRoute = lazy(() => import('@/routes/create-node/CreateOrganizationNodeRoute'))
const CreateClusterNodeRoute = lazy(() => import('@/routes/create-node/CreateClusterNodeRoute'))
const CreateCompanyVisitNodeRoute = lazy(() => import('@/routes/create-node/CreateCompanyVisitNodeRoute'))

const ShowGraphRoute = lazy(() => import('@/routes/graph/ShowGraphRoute'))
const ShowGraphNodeRoute = lazy(() => import('@/routes/graph/nodes/ShowGraphNodeRoute'))
const CreateGraphNodeRoute = lazy(() => import('@/routes/graph/nodes/CreateGraphNodeRoute'))
const EditGraphNodeRoute = lazy(() => import('@/routes/graph/nodes/EditGraphNodeRoute'))

const ProjectsIndexRoute = lazy(() => import('@/routes/ProjectsIndexRoute'))
const EventsIndexRoute = lazy(() => import('@/routes/EventsIndexRoute'))

const LogoutRoute = lazy(() => import('@/routes/account/LogoutRoute'))
const VerifyAccountRoute = lazy(() => import('@/routes/account/VerifyAccountRoute'))
// const AccountSettingsRoute = lazy(() => import('@/routes/account/AccountSettingsRoute'))

const AccountChangePasswordRoute = lazy(() => import('@/routes/account/AccountChangePasswordRoute'))
const AccountEditProfileRoute = lazy(() => import('@/routes/account/AccountEditProfileRoute'))

const AccountNotificationsIndexRoute = lazy(() => import('@/routes/account/notifications/AccountNotificationsIndexRoute'))

const AccountSubscriptionsIndexRoute = lazy(() => import('@/routes/account/subscriptions/AccountSubscriptionsIndexRoute'))
const AccountSubscriptionsEditRoute = lazy(() => import('@/routes/account/subscriptions/AccountSubscriptionsEditRoute'))
const CreateAccountSubscriptionRoute = lazy(() => import('@/routes/account/subscriptions/CreateAccountSubscriptionRoute'))

const AdminIndexRoute = lazy(() => import('@/routes/admin/AdminIndexRoute'))
const AdminWhitelistedDomainsRoute = lazy(() => import('@/routes/admin/AdminWhitelistedDomainsRoute'))
const AdminUsersIndexRoute = lazy(() => import('@/routes/admin/AdminUsersIndexRoute'))
const AdminUsersEditRoute = lazy(() => import('@/routes/admin/AdminUsersEditRoute'))
const AdminLocationsRoute = lazy(() => import('@/routes/admin/AdminLocationsRoute'))
const AdminFieldsRoute = lazy(() => import('@/routes/admin/AdminFieldsRoute'))

const App: FC = () => {
  const workspaceSlug = workspaceSlugFromPath()
  const { data } = useWorkspaceQuery({ slug: workspaceSlug })
  const workspace = useMemo(() => data?.workspace, [data])
  
  return (
    <Suspense>
      <Routes>
      {!workspace ? (
        <Route path='*' element={<SelectWorkspaceRoute />} />
      ):(
        <Route path={workspaceSlug} element={<AppLayout />}>
          <Route path='auth' element={<UnauthorizedLayout />}>
            <Route path='login' element={<LoginRoute />} />
            <Route path='forgot-password' element={<ForgotPasswordRoute />} />
            <Route path='signup' element={<SignupRoute />}>
              <Route index element={<CreateAccountRoute />} />
              <Route path='finish' element={<FinishSignupRoute />} />
              <Route path='confirm-email' element={<ConfirmEmailRoute />} />
            </Route>
          </Route>

          <Route path='admin' element={<AdminIndexRoute />}>
            <Route path='users'>
              <Route index element={<AdminUsersIndexRoute />} />
              <Route path=':userId'>
                <Route index element={<Navigate to='edit' />} />
                <Route path='edit' element={<AdminUsersEditRoute />} />
              </Route>
            </Route>
            <Route path='settings' element={<AdminSettingsRoute/>} />
            <Route path='whitelisted-domains' element={<AdminWhitelistedDomainsRoute />} />
            <Route path='locations' element={<AdminLocationsRoute />} />
            <Route path='fields' element={<AdminFieldsRoute />} />
            <Route path='*' element={<Navigate to='users' />} />
            <Route path='workspaces' element={<SuperAdminRoute/>} />
          </Route>

          <Route path='*' element={<AuthorizedUserLayout />}>
            <Route index element={<StartpageRoute />} />
            <Route path='feed' element={<UserFeedRoute />} />
            <Route path='events' element={<EventsIndexRoute />} />
            <Route path='projects' element={<ProjectsIndexRoute />} />
            <Route path='create-node'>
              <Route index element={<CreateNodeRoute />} />
              <Route path='person' element={<CreatePersonNodeRoute />} />
              <Route path='company' element={<CreateCompanyNodeRoute />} />
              <Route path='event' element={<CreateEventNodeRoute />} />
              <Route path='project' element={<CreateProjectNodeRoute />} />
              <Route path='organization' element={<CreateOrganizationNodeRoute />} />
              <Route path='cluster' element={<CreateClusterNodeRoute />} />
              <Route path='company-visit' element={<CreateCompanyVisitNodeRoute />} />
            </Route>
            <Route path='nodes'>
              <Route index element={<Navigate to={getStartpageRoute()} />} />
            </Route>
            <Route path='graph'>
              <Route index element={<Navigate to={getStartpageRoute()} />} />
              <Route path=':searchQuery' element={<ShowGraphRoute />}>
                <Route path='new' element={<CreateGraphNodeRoute />} />
                <Route path='subscriptions'>
                  <Route index element={<Navigate to='new' />} />
                  <Route path='new' element={<CreateAccountSubscriptionRoute />} />
                </Route>
                <Route path=':selectedNodeIds'>
                  <Route index element={<ShowGraphNodeRoute />} />
                  <Route path='edit' element={<EditGraphNodeRoute />} />
                </Route>
              </Route>
            </Route>
            <Route path='account' element={<AccountSettingsLayout />}>
              <Route index element={<Navigate to='settings' />} />
              {/* <Route path='settings' element={<AccountSettingsRoute />} /> */}
              <Route path='notifications' element={<AccountNotificationsIndexRoute />} />
              <Route path='change-password' element={<AccountChangePasswordRoute />} />
              <Route path='edit-profile' element={<AccountEditProfileRoute />} />
              <Route path='subscriptions'>
                <Route index element={<AccountSubscriptionsIndexRoute />} />
                <Route path='new' element={<CreateAccountSubscriptionRoute />} />
                <Route path=':subscriptionId'>
                  <Route index element={<Navigate to='edit' />} />
                  <Route path='edit' element={<AccountSubscriptionsEditRoute />} />
                </Route>
              </Route>
              {/* <Route path='create' element={<CreateAccountRoute />} /> */}
              <Route path='forgot' element={<ForgotPasswordRoute />} />
              <Route path='verify' element={<VerifyAccountRoute />} />
              <Route path='logout' element={<LogoutRoute />} />
            </Route>
            <Route path='*' element={<NotFoundRoute />} />
          </Route>
        </Route>
      )}
      </Routes>
    </Suspense>
  )
}

export default App
