import { gql } from 'graphql-request'

import WorkspaceParts from './fragments/WorkspaceParts'

export const WhitlistedDomainsQuery = gql`
  query WhitlistedDomains {
    whitelistedDomains
  }
`

export const Workspace = gql`
  query Workspace($slug: String!) {
    workspace(slug: $slug){
      ...WorkspaceParts
      description
    }
  }
  ${WorkspaceParts}
`

export const Workspaces = gql`
  query Workspaces($offest: Int, $limit: Int, $includeDisabled: Boolean = true) {
    workspaces(offset: $offest, limit: $limit, includeDisabled: $includeDisabled) {
      ...WorkspaceParts
    }
    workspacesCount
  }
  ${WorkspaceParts}
`

export const CreateWorkspace = gql`
  mutation CreateWorkspace($name: String!) {
    createWorkspace(name: $name){
      name
    }
  }
`

export const ToggleWorkspace = gql`
  mutation ToggleWorkspace($slug: String!) {
    toggleWorkspace(slug: $slug) {
      disabled
    }
  }
`

export const UpdateWorkspace = gql`
  mutation UpdateWorkspace($name: String , $description: String) {
    updateWorkspace(name: $name, description: $description){
      name
      description
    }
  }
`

export const UpdateWhitelistedDomains = gql`
  mutation UpdateWhitelistedDomains($domains: [String!]!) {
    updateWhitelistedDomains(domains: $domains)
  }
`

export const PingQuery = gql`
  query Ping {
    ping
  }
`
