import classNames from 'classnames'
import { ButtonHTMLAttributes, ElementType, FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  link?: string
  variant?: 'primary' | 'secondary' | 'light' | 'white' | 'attention'
  size?: 'sm' | 'default' | 'lg'
  iconLeft?: ReactNode
  iconRight?: ReactNode
  buttonComponent?: ElementType
}

const Button: FC<ButtonProps> = ({
  size = 'default',
  variant = 'primary',
  iconLeft,
  iconRight,
  children,
  link,
  className,
  buttonComponent,
  ...rest
}) => {
  const baseClass = 'rounded font-bold'
  const focusClass = classNames(
    'focus:outline-none focus:ring-2 focus:ring-offset-2',
    variant === 'attention' && 'focus:ring-attention-100',
    variant === 'light' && 'focus:ring-dark-20',
    variant !== 'attention' && variant !== 'light' && 'focus:ring-primary-100',
  )

  const sizeClass = classNames(
    size === 'sm' && 'py-sm px-sm text-sm',
    size === 'default' && 'px-md py-sm text-base',
    size === 'lg' && 'py-[20px] px-md text-base',
  )

  const variantClass = classNames(
    variant === 'primary' && 'bg-primary-100 text-white hover:bg-primary-80',
    variant === 'secondary' && 'bg-primary-5 text-primary-100 hover:bg-primary-10',
    variant === 'light' && 'bg-dark-5 text-dark-100 hover:bg-dark-10',
    variant === 'white' && 'bg-white text-dark-100 hover:bg-dark-5',
    variant === 'attention' && 'bg-attention-100 text-white hover:bg-opacity-80',
  )

  const classes = classNames(baseClass, variantClass, sizeClass, focusClass, rest.disabled && 'opacity-30', className)
  const buttonContent = (
    <span className='relative flex flex-row items-center justify-center'>
      {iconLeft && <div className='mr-sm'>{iconLeft}</div>}
      {children}
      {iconRight && <div className='ml-sm'>{iconRight}</div>}
    </span>
  )

  if (link) {
    return (
      <Link to={link}>
        <button {...rest} className={classes}>
          {buttonContent}
        </button>
      </Link>
    )
  } else if (!!buttonComponent) {
    const Btn = buttonComponent
    return (
      <Btn {...rest} className={classes}>
        {buttonContent}
      </Btn>
    )
  } else {
    return (
      <button {...rest} className={classes}>
        {buttonContent}
      </button>
    )
  }
}

export default Button
