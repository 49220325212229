import { getApps, initializeApp } from 'firebase/app'

import env from './env'

let firebaseConfig: Parameters<typeof initializeApp>[0] = {
  apiKey: 'AIzaSyCJUHxGs1SqNTjFI-iccviwu7hOmRgbYJE',
  authDomain: 'esbn-staging.firebaseapp.com',
  projectId: 'esbn-staging',
  storageBucket: 'esbn-staging.appspot.com',
  messagingSenderId: '17806378186',
  appId: '1:17806378186:web:8d93b1319f8ff57cdce033',
}

if (env.VITE_FIREBASE_ENV === 'production')
  firebaseConfig = {
    apiKey: 'AIzaSyCqbCQJXa2Y5tkjeEq7qfDBZlh71Pp-Tv4',
    authDomain: 'esbn-production.firebaseapp.com',
    projectId: 'esbn-production',
    storageBucket: 'esbn-production.appspot.com',
    messagingSenderId: '871807479827',
    appId: '1:871807479827:web:1a65a1c33f355049018fe6',
  }

if (getApps().length === 0) initializeApp(firebaseConfig)
