import React, { FC, MutableRefObject, PropsWithChildren } from 'react'

import Modal from './Modal'
import ModalConfirmButtons, { ModalActionButtonProps } from './ModalActionButtons'
import ModalHeader, { ModalHeaderProps } from './ModalHeader'

export interface ModalProps extends Pick<ModalActionButtonProps, 'variant' | 'confirmButtonText' | 'cancelButtonText'>, ModalHeaderProps {
  open: boolean
  className?: string
  initialFocus?: MutableRefObject<HTMLElement | null> | undefined
  onClose: () => void
  onSubmit: () => void
}

const FormModal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  initialFocus,
  children,
  title,
  description,
  onSubmit,
  className,
  confirmButtonText,
  cancelButtonText,
  variant,
}) => {
  return (
    <Modal onClose={onClose} open={open} initialFocus={initialFocus} className={className}>
      <ModalHeader title={title} description={description} />
      <form id='modal-content' onSubmit={onSubmit}>
        {children}
        <ModalConfirmButtons
          isForm
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          variant={variant}
          onCancel={onClose}
        />
      </form>
    </Modal>
  )
}

export default FormModal
