import classNames from 'classnames'
import { InputHTMLAttributes, forwardRef } from 'react'

import './Toggle.css'

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  helpText?: string
}

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(({ label, helpText, error, className, ...rest }, ref) => {
  return (
    <div className='flex flex-col'>
      {label && <label className='text-dark-100 mb-half inline-block px-1 text-sm font-bold'>{label}</label>}
      <input type='checkbox' className='sr-only absolute inset-0' {...rest} ref={ref} />
      <label
        className={classNames(
          'slider:bg-primary-100 h-md w-2xl outline-dark-20 relative inline-flex shrink-0 cursor-pointer rounded-full outline',
          'bg-dark-20 border-2 border-transparent transition-colors duration-200 ease-in-out',
          'focus-within:ring-primary-100 focus-within:ring-2 focus-within:ring-offset-2',
        )}
      >
        <span className='sr-only'>Use setting</span>
        <span
          aria-hidden='true'
          className={classNames(
            'slider:translate-x-md h-md w-md pointer-events-none inline-block',
            'translate-x-0 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </label>
      {!!error && <p className='text-attention-125 mt-xs text-sm font-bold'>{error}</p>}
      {!!helpText && !error && <p className='text-dark-80 mt-xs text-sm'>{helpText}</p>}
    </div>
  )
})

Toggle.displayName = 'Toggle'

export default Toggle
