import { useQueryClient } from '@tanstack/react-query'
import { FC } from 'react'
import toast from 'react-hot-toast'

import { WorkspacePartsFragment, useToggleWorkspaceMutation } from '@/generated'
import useOpenState from '@/hooks/useOpenState'

import AlertModal from '../ui/AlertModal'
import Toggle from '../ui/Toggle'


interface props {
  workspace: WorkspacePartsFragment
  disabled?: boolean
}

const WorkspaceToggle:FC<props> = ({ workspace, disabled = false }) => {
  const [modalOpen, openModal, closeModal] = useOpenState()
  const queryClient = useQueryClient()
  const { mutateAsync } = useToggleWorkspaceMutation({ onSettled: () => queryClient.refetchQueries(['Workspaces']) })

  const handleOnClick = () => !workspace.disabled ? openModal() : toggleWorkspace()
  

  const toggleWorkspace = async () => {
    const toastId = toast.loading(`${workspace.disabled ? 'Aktiverar' : 'Avaktiverar'} ${workspace.name}...`)
    try {
      await mutateAsync({slug: workspace.slug})
      toast.success(`${workspace.name} har ${workspace.disabled ? 'aktiverats' : 'avaktiverats'}`, { id: toastId })
      closeModal()
    } catch (e) {
      toast.error(`Någonting gick fel när ${workspace.name} skulle ${workspace.disabled ? 'aktiveras' : 'avaktiveras'}`, { id: toastId })
    }
  }
  
  return (
    <>
      <div onClick={handleOnClick} className={disabled ? 'pointer-events-none opacity-30' : ''}>
        <Toggle checked={!workspace.disabled} />
      </div>
      <AlertModal
        open={modalOpen}
        onClose={closeModal}
        title={'Avaktivera'}
        className='w-full max-w-lg'
        description={`Är du säker på att du vill avaktivera ${workspace.name || 'detta workspace'}?`}
        onConfirm={toggleWorkspace}
        confirmButtonText='Avaktivera'
        cancelButtonText='Avbryt'
        variant='attention'
      />
    </>
  )
}

export default WorkspaceToggle